// Step 1: Import React
import * as React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import { SalWrapper } from "react-sal"
import Button from '../components/buttons/button'
import Banner from "../imgs/bg_sustentabilidad.jpg";
import Image from "../components/image/image";
import "./sustentabilidad.css";

let text = `Estamos profundamente comprometidos con proteger y cuidar el medio ambiente. <span class='highlightsbold'> #SomosSustentables</span>`;
const pageTitle = '#SomosSustentables | Balanz';
const pageDesc = 'En Balanz nos comprometemos profundamente a proteger y cuidar el medio ambiente. Conocé nuestra política ambiental.';

const SustentabilidadPage = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerSustentabilidad"
                bannerTrasparecy=""
                productTitle="Sustentabilidad"
                bannerImage={Banner}
                bannerImageClassName="sustentabilidadBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText3"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <SalWrapper>
            <section className="fullwidth page-description">
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="intro-text base-text pt-2 pt-sm-3 pt-md-4 pt-lg-5"
                               data-sal="slide-up"
                               data-sal-delay="0"
                               data-sal-duration="200"
                            >
                                Creemos que los cambios se hacen de raíz, es por esto que creamos nuestro propio Sistema de Gestión Ambiental mediante el cual promovemos hábitos responsables de consumo, impulsamos una cultura integral de reciclaje e incorporamos diariamente el uso de tecnología sustentable.
                            </p>
                            <p className="intro-text base-text pt-2 pt-sm-3 pt-md-4 pt-lg-5"
                               data-sal="slide-up"
                               data-sal-delay="0"
                               data-sal-duration="200"
                            >
                                Nuestra Política Ambiental es una guía fundamental para seguir perfeccionando continuamente este sistema, y así mejorar nuestro desempeño ambiental todos los días.
                            </p>
                            <p className="intro-text base-text pt-2 pt-sm-3 pt-md-4 pt-lg-5"
                               data-sal="slide-up"
                               data-sal-delay="0"
                               data-sal-duration="200"
                            >
                            Estamos felices de poder comunicar que hemos certificado la norma internacional ISO 14.001. Con esta norma hemos conseguido alcanzar altos estándares de gestión ambiental a través de nuestro Sistema, donde plasmamos nuestro compromiso y responsabilidad de proteger el medio ambiente.
                            </p>
                            <p className="intro-text base-text pt-2 pt-sm-3 pt-md-4 pt-lg-5"
                               data-sal="slide-up"
                               data-sal-delay="0"
                               data-sal-duration="200"
                            >
                                Porque con cada paso que damos, nos acercamos a un ambiente más saludable.
                            </p>
                            <div className="py-3 py-md-4 py-lg-5"
                                 data-sal="fade"
                                 data-sal-duration="100"
                            ><Button variant="green" text="Leé nuestra Política Ambiental" onClick={() => setModalShow(true)} /></div>
                        </Col>
                    </Row>
                </Container>
            </section>
            </SalWrapper>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"sustentabilidad"}></Layout>
            <Modal show={modalShow} onHide={() => setModalShow(false)}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Body>
                    <Image
                        className="sustRules"
                        src="politica-ambiental.png"
                        alt="Politica Ambiental"
                    />
                </Modal.Body>
            </Modal>
        </main>
    );
};


// Step 3: Export your component
export default SustentabilidadPage;